import 'slick-carousel';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

jQuery(window).scroll(scrollCheck);

function scrollCheck(){       
      // if (jQuery('#is_desctop').is(':visible')){
        if (jQuery(this).scrollTop() >= 50) {
            jQuery('body').addClass('scrolled');
        }
        else {
            jQuery('body').removeClass('scrolled');
        }
      // }
    
}

function scrollTo(hash){
  $('html, body').animate({
    scrollTop: $(hash).offset().top - 100
  }, 500);
}


jQuery(document).ready(function($){
    
    $('html').addClass('loaded');

    updateContent($('body'));


    $('a').each(function(){

      var h = $(this).attr('href');
      var hash = h.split("#")[1];

      if(hash){

        $(this).click(function(){
          scrollTo('#'+hash);
          return false;
        })

      }

    });

    $('.open_answer').each(function(){
      $(this).on('click', function(){

        var target = $(this).attr('data_target');

        $('.faq_item').each(function(){

          if($(this).attr('id') != target){
            $(this).removeClass('opened');
          }
        });
        
        $('#'+target).toggleClass('opened');
        return false;
      });
    });



    $('#headerSlider .slider-menu li.active span.underline.active').each(function(){
      $(this).addClass('animate');
    });
});



function updateContent(parent){
    $(parent).find('.slickSlider').each(function(){
      var ID = $(this).attr('id');
      var resp = $(this).attr('slick_per_page').split('_');
      var showDots = $(this).attr('slick_show_dots') && $(this).attr('slick_show_dots')=='1' ? true : false;
      var hideArrows = $(this).attr('slick_hide_arrows') && $(this).attr('slick_hide_arrows')=='1' ? false : true;
      var varWidth = $(this).attr('variable_width') && $(this).attr('variable_width')=='1' ? true : false;
      var center_mode = $(this).attr('center_mode') && $(this).attr('center_mode')=='0' ? false : true;
      var speed = $(this).attr('speed') ? $(this).attr('speed') : 5000;
      var fade = $(this).attr('fade') && $(this).attr('fade')=='1' ? true : false;
  
      var resp_1 = circumference(resp[0] ? resp[0] : 4);
      var resp_2 = circumference(resp[1] ? resp[1] : 3);
      var resp_3 = circumference(resp[2] ? resp[2] : 1);
      var resp_4 = circumference(resp[3] ? resp[3] : 1);
  
      var customArrows = $(this).attr('navigation') ? true : false;
      var prevArr = customArrows ? $('#'+$(this).attr('navigation')+' .slick-prev') : '<button class="slick-prev slick-arrow slick-disabled" aria-label="Previous" type="button" aria-disabled="true" style="">Previous</button>';
      var nextArr = customArrows ? $('#'+$(this).attr('navigation')+' .slick-next') : '<button class="slick-next slick-arrow slick-disabled" aria-label="Next" type="button" style="" aria-disabled="true">Next</button>';
  
      $('#'+ID).slick({
        dots: showDots,
        arrows: hideArrows,
        infinite: true,
        speed: 500,
        fade: fade,
        autoplay: true,
        autoplaySpeed: speed,
        variableWidth: varWidth,
        centerMode: false, 
        cssEase: 'linear',
        slidesToShow: resp_1,
        slidesToScroll: resp_1,
        swipeToSlide: true,
        prevArrow: prevArr,
        nextArrow: nextArr,
        pauseOnHover:false,
  
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: resp_2,
              slidesToScroll: resp_2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 920,
            settings: {
              slidesToShow: resp_3,
              slidesToScroll: resp_3,
              variableWidth: false
            }
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: resp_4,
              slidesToScroll: 1,
              centerMode: center_mode, 
              variableWidth: true
            }
          }
  
        ]
      }); 
    
    $('#'+ID).on('afterChange', slickInit);
    $('#'+ID).trigger('afterChange');

    $('#'+ ID).find('.slider-menu li').each(function(){
        $(this).on('click', function(){
          
            $('#'+ ID).slick('slickGoTo', $(this).attr('slider-index'));
            return false;
        });
    });
    
  });
  }

  function circumference(r) {
    if (Number.isNaN(Number.parseFloat(r))) {
      return 0;
    }
    return parseFloat(r) ;
  }

  function slickInit(slick, currentTarget){
    // console.log(currentTarget)
    // $(event.currentTarget).find('.loadAjax').each(function(){
    //     $(this).on('click', loadAjax);
    // });
  }